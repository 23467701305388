<template>
    <CEESAR-ControlBox  :label="label"
                        :required="required"
                        :horizontal="horizontal">

        <select class="CEESAR-InputBoxField"
                v-bind:value="value" v-on:change="$emit('change', $event.target.value)">
            <option v-for="entry in entries" v-bind:key="entry.id" :value="entry.id">
                {{entry.label}}
            </option>
        </select>
    </CEESAR-ControlBox>
</template>

<script>
export default {
    name:"CEESAR-ComboBox",
    props:{
        label: {type: String, default: "" },
        entries: {type: Array, default: []},
        value: {type: Number, default: ""},
        horizontal: {type: Boolean, default: false},
        required: {type: Boolean, default: false}
    },
    data(){
        return{
            isExpanded: false,
            selectedId: this.value
        };
    },
    computed:{
        selectionLabel(){
            let selection = this.entries.find(x => x.id == this.selectedId);
            if(selection != undefined)
                return selection.label;
            return "<>";
        }
    },
    methods:{
        ToggleShowContent(e){
            this.isExpanded = !this.isExpanded;
            e.stopPropagation();
        },
        HideContent(){
            this.isExpanded = false;
        },
        setSelectedId(pId){
            this.$emit("change", pId);
            this.selectedId = pId;
            this.HideContent();
        }
    }
}
</script>

<style lang="less">

</style>